import { Controller } from "@hotwired/stimulus";
import lightGallery from "lightgallery";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";

const LICENSE_KEY = "CA44C61E-270E40AD-A3B1903A-94D1E9D6";

export default class extends Controller {
  static targets = ["gallery"];

  connect() {
    const plugins = [lgZoom];

    if (this.galleryTarget.dataset.showThumbnails === "true") {
      plugins.push(lgThumbnail);
    }

    this.lightGallery = lightGallery(this.galleryTarget, {
      plugins,
      licenseKey: LICENSE_KEY,
    });
  }

  openGallery(event) {
    this.lightGallery.openGallery(event.params.index);
  }
}
