import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "form", "acceptCheckbox"];

  connect() {
    this.acceptCheckboxTarget.disabled = false;
    this.acceptCheckboxTarget.classList.remove("disabled");
  }

  toggleSubmitEnabled() {
    this.submitButtonTarget.disabled = !this.submitButtonTarget.disabled;
    this.submitButtonTarget.classList.toggle("disabled");
  }

  submitForm() {
    plausible("Neuer Loipenreporter");
  }
}
