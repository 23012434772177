import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addReactionItems", "showAddableReactionsInput"];
  static values = { showAddableReactions: Boolean };

  toggleAddReactions() {
    this.addReactionItemsTargets.forEach((target) =>
      target.classList.toggle("d-none")
    );
    this.showAddableReactionsValue = !this.showAddableReactionsValue;
  }

  setShowAddableReactions() {
    this.showAddableReactionsInputTargets.forEach((target) => {
      target.value = this.showAddableReactionsValue.toString();
    });
  }
}
