import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    adjustMarginTop() {
        const footer = $('#footer');
        const docHeight = $(window).height();
        const footerHeight = footer.outerHeight();
        const footerTop = footer.position().top + footerHeight;

        if (footerTop < docHeight) {
            footer.css('margin-top', 10 + (docHeight - footerTop) + 'px');
        }
    }
}
