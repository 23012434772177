import { Controller } from "@hotwired/stimulus";
import * as $ from "jquery";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    $(document).ready(function () {
      $("input[maxlength]").maxlength({
        alwaysShow: true,
        warningClass: "small text-muted mt-1",
        limitReachedClass: "small text-muted mt-1",
        placement: "bottom-right-inside",
      });

      $(".select-trails").select2({
        tags: true,
        placeholder: "Auswählen oder neue Loipe eintragen",
        theme: "bootstrap4",
        language: {
          noResults: function () {
            return "Noch keine Loipen vorhanden. Bitte trage eine neue Loipe in diesem Feld ein.";
          },
        },
        createTag: function (params) {
          var term = $.trim(params.term);

          if (term === "") {
            return null;
          }

          return {
            id: "<<<" + term + ">>>",
            text: term,
          };
        },
        formatSelectionCssClass: function (data, container) {
          return "select-token";
        },
      });
    });
  }

  submitForm() {
    plausible("Neuer Loipenbericht");
  }
}
