import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "regionSelector",
    "reportsCountSelector",
    "toggleStarsCheckbox",
    "togglePreviewTextCheckbox",
  ];

  connect() {
    this.updateEmbedURL();
  }

  updateEmbedURL() {
    const BASE_URL = location.origin;
    const PATH = "berichte/embed/v1";
    const INITIAL_REGION = "harz";

    const region = $(this.regionSelectorTarget).val() || INITIAL_REGION;
    const showStars = $(this.toggleStarsCheckboxTarget).is(":checked");
    const showPreviewText = $(this.togglePreviewTextCheckboxTarget).is(
      ":checked"
    );
    const numberOfNews = $(this.reportsCountSelectorTarget).val();

    let embedURL = `${BASE_URL}/${region}/${PATH}`;
    let getParams = [];

    if (showStars) {
      getParams.push("show_stars=1");
    }

    if (showPreviewText) {
      getParams.push("show_preview_text=1");
    }

    getParams.push("num_reports=" + numberOfNews);

    if (getParams.length > 0) {
      embedURL += "?";
      embedURL += getParams.join("&");
    }

    $("#embed-url").html(embedURL);
    $("#embed-preview").attr("src", embedURL);
  }
}
