import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  adjustTextareaHeight(event) {
    const textArea = $(event.currentTarget);

    while (
      textArea.outerHeight() <
      event.currentTarget.scrollHeight +
        parseFloat(textArea.css("borderTopWidth")) +
        parseFloat(textArea.css("borderBottomWidth"))
    ) {
      textArea.height(textArea.height() + 1);
    }
  }
}
