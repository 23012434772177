// Rails-related JS
import * as ActiveStorage from "@rails/activestorage";
import { Turbo } from "@hotwired/turbo-rails";

// Stimulus.js
import "./controllers";

import "bootstrap";
import "select2";

ActiveStorage.start();

window.jQuery = $;
window.$ = $;

// jQuery plugins and initialization
import "./src/bootstrap-maxlength";
